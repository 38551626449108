import {Controller} from 'stimulus';
import {I18n} from '../translation';

export default class extends Controller {
  currentStep=1;
  secondStepper = document.getElementById('stepper2-icons');
  thirdStepper = document.getElementById('stepper3-icons');
  selectStepper(event){
    this.selectStep(event.currentTarget.dataset.step);
  }

  selectContinue(){
    this.selectStep(this.currentStep +1);
    this.secondStepper.innerHTML = '<i class="material-icons-outlined !text-[16px] text-white state-icon">done</i>';
    this.thirdStepper.innerHTML = `<div class="sk-checkout-stepper__step-status flex items-center justify-center w-[16px] h-[16px] rounded-full my-0 mx-auto">
                                       <div class="sk-checkout-stepper__step-dot bg-gray-400 p-[4px] rounded-full"></div>
                                   </div>
                                   <p class="absolute -ml-[18px] text-[14px]">${I18n[window.currentLocale]['shipping']}</p>`;
  }

  previousStep(){
    this.selectStep(this.currentStep);
    if (document.getElementById('guest_checkout_email')){
      document.getElementById('guest_checkout_email').value = '';
    }
    this.secondStepper.innerHTML = '<div class="sk-checkout-stepper__step-dot bg-gray-400 p-[4px] rounded-full"></div>';
    this.thirdStepper.innerHTML = `<div class="sk-checkout-stepper__step-status flex items-center justify-center w-[12px] h-[12px] !bg-gray-400 rounded-full my-0 mx-auto">
                                      <div class="sk-checkout-stepper__step-dot bg-gray-400 rounded-full"></div>
                                   </div>
                                   <p class="absolute -ml-[18px] text-[14px]">${I18n[window.currentLocale]['shipping']}</p>`;
  }

  selectStep(step) {
    const childElements = document.getElementById('cart-stepper-content').children;
    for (let i = 1; i <= childElements.length; i++) {
      if(step === i){
        childElements[i-1].classList.remove('hidden');
        window.scrollTo(0,0);
      } else {
        childElements[i-1].classList.add('hidden');
      }
    }
  }
}
