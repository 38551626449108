import {Controller} from 'stimulus';

export default class extends Controller {
  orderState = this.element.dataset.order_state;
  static targets = ['orderCancel'];

  clearForm() {
    document.getElementById('order_state_update_button').disabled = true;
    const cancelElement = this.element.querySelector('#order_cancel');
    if (cancelElement) {
      cancelElement.classList.add('hidden');
    }
  }

  click_open() {
    this.hide_element();
    document.getElementById('order_state_update_button').disabled = this.orderState === 'cart';
  }

  click_ready_for_dispatch() {
    this.hide_element();
    document.getElementById('order_state_update_button').disabled = this.orderState === 'ready_for_dispatch';
  }

  click_delivered() {
    this.hide_element();
    document.getElementById('order_state_update_button').disabled = this.orderState === 'delivered';
  }

  click_close() {
    this.hide_element();
    document.getElementById('order_state_update_button').disabled = this.orderState === 'complete';
  }

  click_in_transit() {
    document.getElementById('order_in_transit').classList.remove('hidden');
    document.getElementById('order_cancel').classList.add('hidden');
    document.getElementById('order_state_update_button').disabled =
      !(document.getElementById('order_transit_id').value.length > 5 && this.orderState !== 'in_transit');
  }

  click_cancel() {
    document.getElementById('order_cancel').classList.remove('hidden');
    document.getElementById('order_in_transit').classList.add('hidden');
    document.getElementById('order_state_update_button').disabled =
      !(document.getElementById('cancel_reason').value.length > 5 && this.orderState !== 'canceled');
  }

  mark_as_picked(event) {
    document.getElementById('order_state_update_button').disabled = !(event.target.checked && this.orderState === 'order_placed');
  }

  hide_element() {
    document.getElementById('order_in_transit').classList.add('hidden');
    document.getElementById('order_cancel').classList.add('hidden');
  }

  in_transit_input(event) {
    document.getElementById('order_state_update_button').disabled =
      !(event.currentTarget.value.length > 5 && this.orderState !== 'in_transit');
  }

  cancel_input(event) {
    document.getElementById('order_state_update_button').disabled =
      !(event.currentTarget.value.length > 5 && this.orderState !== 'canceled');
  }

  uncheckedCheckBox() {
    document.getElementById('mark-picked-toggle').checked = false;
  }

  outSideModalClick(event) {
    const modal = document.getElementById('markConfirmModal');
    const checkbox = document.getElementById('mark-picked-toggle');

    if (modal.contains(event.target)) {
      if (checkbox.checked) {
        checkbox.checked = false;
      }
    }
  }
}