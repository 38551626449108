import {Controller} from 'stimulus';

export default class extends Controller {
  connect() {
    // eslint-disable-next-line no-undef
    if (this.element.dataset.gtmInfo) {
      dataLayer.push({ecommerce: null});
      dataLayer.push(
        {
          event: "purchase",
          ecommerce: JSON.parse(this.element.dataset.gtmInfo)
        }
      );
    }
  }
}
