export const I18nEN = {
  confirm_member: 'Please check if you are an official member',
  uncheck_member: 'Please uncheck if you are not an official member.',
  password_requirement:
    'Required uppercase, lowercase, number, special character and minimum of 8 character.',
  name: 'Name: ',
  phone: 'Phone: ',
  state: 'State: ',
  country: 'Country: ',
  apartment: 'Apartment/Unit/Building number: ',
  zipcode: 'Zipcode: ',
  permission_changed: 'Permission update successful.',
  something_went_wrong: 'Something went wrong.',
  required_field: 'Please fill up the input fields.',
  role_update_success: 'New role assigned.',
  role_update_failure: 'Error assigning role.',
  snack_msg_on_user_deletion: 'Selected user(s) successfully deleted.',
  snack_msg_on_user_removal:
    'Selected user(s) successfully removed from the current role.',
  snack_msg_on_subcategory_removal:
    'Selected subcategory(s) successfully removed from the current category.',
  admin_invitation: 'User invite successfully sent.',
  admin_invitation_failure: 'Error sending invite.',
  people: 'People',
  cms: 'Web CMS',
  retailers: 'Retailers',
  successfully_subscribed: 'Successfully Subscribed',
  product_configurator: 'Product Configurator',
  inventory_management: 'Inventory Management',
  order_management: 'Order Management',
  seo_management: 'SEO Management',
  gift_card_detail: 'Gift Card',
  product_tags: 'Product Tags',
  products: 'Products',
  reports: 'Reports',
  promotion: 'Promotion',
  remove_subcategory: 'Remove Subcategory',
  sub_category: 'Subcategory name',
  product_type_creation_notice: 'Product type successfully created.',
  category_creation_notice: 'Category successfully created.',
  sub_category_creation_notice: 'Subcategory successfully created.',
  content_duplication_success_notice: 'Copied',
  content_duplication_success_error: 'Error Copying.',
  add_option_value: 'Add value',
  selected: 'Selected',
  variant: 'Variant',
  stock: 'Stock',
  continue_as_guest: 'Continue as guest',
  its_my_first_time: 'It\'s my first time at the starting line',
  continue_with_registration: 'Continue with registration',
  initial_stock_quantity: 'Initial Stock Quantity',
  product_type_edition_notice: 'Product type successfully updated.',
  category_edition_notice: 'Category successfully updated.',
  subcategory_edition_notice: 'Subcategory successfully updated.',
  variant_creation_success_notice: 'Variant successfully created.',
  variant_updation_success_notice: 'Variant successfully updated.',
  order_state_updated_successful: 'Order state updated successfully',
  order_state_updated_unsuccessful: 'Order state updated unsuccessful',
  active_product: 'Product visibility on',
  inactive_product: 'Product visibility off',
  product_added_cart: 'Product added to cart.',
  field_required: 'Field Required',
  invalid_email: 'Invalid Email',
  password_should_match: 'Password Should Match.',
  invalid_member_id: 'Invalid Member ID',
  invalid_phone_example: 'Invalid Phone No. - Example:',
  must_have_one_product: 'You must have at least one image',
  file_too_large: 'File size is too large',
  already_in_cart:
    'The selected Product is already in cart, please increase the quantity from the cart.',
  limit_stock_reached: 'Limit Stock Reached for the Product.',
  minimum_order_quantity:
    'Cannot order item quantity less than 1, please remove the item by pressing the cross button',
  brands: 'Brands',
  color: 'Color',
  size: 'Size',
  first_name: 'First Name: ',
  last_name: 'Last Name: ',
  street_name: 'Street Name: ',
  city: 'City: ',
  change: 'Change',
  invalid_zipcode: 'Invalid zipcode',
  successful_copy: 'Successfully Copied.',
  discount_deleted: 'Discount Deleted Successfully',
  invalid_value: 'Invalid Value',
  invalid_timing: 'Invalid Timing',
  promo_applied_successfully: 'Promo applied successfully',
  gift_card_applied_successfully: 'Gift card applied successfully',
  gift_card_removed_successfully: 'Gift card removed successfully',
  for_specific_product: 'For Specific Product',
  for_specific_collection: 'For Specific Collection',
  for_all_product: 'For All Product',
  invalid_street: 'Invalid Street Address.- Example: Altweg 47',
  internal_categories: 'Internal Categories',
  categories: 'Categories',
  email_exist: 'Email already exist',
  please_login: 'please login',
  payment: 'Payment',
  shipping: 'Shipping',
  No_gift_product_selected: 'No gift product selected',
  for_membership: 'For Membership',
  snowactive: 'Free Snowactive magazine (4x a year by post) / Newsletter',
  snow_active_newsletter: 'Newsletter',
  fill_required_fields: 'Please fill required field (s)',
  stay_up_to_date: 'Stay up to date about the swiss-ski teams',
  subscribe_now: 'Subscribe Now',
  for_you: 'For you',
  members_benefit: 'Members Benefit',
  place_order: 'Place Order',
  proceed_to_payment: 'Proceed to payment',
  retailer_selected: 'Retailer already selected.',
  retailer_success: 'Retailer selected successfully.',
  retailer_unsuccessful: 'Fachhändler erfolgreich ausgewählt.',
  sub_categories: 'Sub Categories',
};
