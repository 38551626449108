import {Controller} from 'stimulus';
import {I18n} from '../translation';

export default class extends Controller {
  static targets = ['noVariantToggle'];
  optionValues;

  connect() {
    if (window.location.pathname.includes('edit')) {
      const sportProducts = JSON.parse(this.element.dataset.productSports);
      sportProducts.forEach(sportId => {
        document.querySelector(`[data-sport-id="${sportId}"]`).click();
      });
    }

    if(this.element.dataset?.hasVariants === 'false') {
      document.getElementById('no_variants').click();
      this.noVariants();
    }
  }

  noVariantCacCtrl(event) {
    event.currentTarget.checked ? this.enableClickAndCollect() : this.disableClickAndCollect();
  }

  enableClickAndCollect() {
    this.noVariantToggleTarget.classList.remove('hidden');
  }

  disableClickAndCollect() {
    this.noVariantToggleTarget.classList.add('hidden');
  }
  hasVariants() {
    this.expandStepper();
    // change the next button to the next step
    document.getElementById('step-two-next-btn').setAttribute('data-next-step', 'pc-step-three');
    document.getElementById('step-six-previous-btn').setAttribute('data-previous-step', 'pc-step-four');

    // update bottom text banner
    document.getElementById('pc-step-two-bottom-with-variant').classList?.remove('hidden');
    document.getElementById('pc-step-two-bottom-without-variant').classList?.add('hidden');
    document.getElementById('pc-step-six-top-with-variants').classList?.remove('hidden');
    document.getElementById('pc-step-six-top-without-variants').classList?.add('hidden');

    // send to BE
    document.getElementById('has-variants').value = true;
  }

  noVariants() {
    this.reduceStepper();

    // change the next button to the next step
    document.getElementById('step-two-next-btn').setAttribute('data-next-step', 'pc-step-five');
    document.getElementById('step-five-previous-btn').setAttribute('data-previous-step', 'pc-step-two');
    document.getElementById('step-six-previous-btn').setAttribute('data-previous-step', 'pc-step-five');

    // update bottom text banner
    document.getElementById('pc-step-two-bottom-without-variant').classList?.remove('hidden');
    document.getElementById('pc-step-two-bottom-with-variant').classList?.add('hidden');
    document.getElementById('pc-step-six-top-without-variants').classList?.remove('hidden');
    document.getElementById('pc-step-six-top-with-variants').classList?.add('hidden');

    document.getElementById('has-variants').value = false;
  }

  expandStepper() {
    document.getElementById('master-variant-barcode').classList.add('hidden');
    document.getElementById('cac-enable-toggle').classList.add('hidden');
    document.querySelector('.pc-step-three').classList.remove('hidden');
    document.querySelector('.pc-step-four').classList.remove('hidden');
    document.querySelector('.pc-step-five').classList.add('hidden');
  }

  reduceStepper() {
    document.getElementById('master-variant-barcode').classList.remove('hidden');
    document.getElementById('cac-enable-toggle').classList.remove('hidden');
    document.querySelector('.pc-step-five').classList.remove('hidden');
    document.querySelector('.pc-step-three').classList.add('hidden');
    document.querySelector('.pc-step-four').classList.add('hidden');
  }

  generateVariants(e) {
    this.getOptionsValues();
    const productId = window.location.pathname.includes('edit') ? window.location.pathname.split('/')[window.location.pathname.split('/').length - 2] : null;
    const radix = e.currentTarget.dataset.radix;
    const parentFormId = e.currentTarget.dataset.parentFormId;
    if(!this.optionValues.en.Color?.length){
      ['en', 'de', 'fr'].forEach(locale => {
        delete this.optionValues[locale].Color;
      });
    }

    fetch(`/admin/generate-product-variants?id=${productId}&radix=${radix}&parent_form_id=${parentFormId}`, {
      method: 'POST',
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify(this.optionValues)
    })
      .then(response => response.text())
      .then(html => {
        // eslint-disable-next-line no-undef
        Turbo.renderStreamMessage(html);
      });

    if(window.location.pathname.includes('edit') && this.element.dataset?.hasVariants === 'true') {
      const variantsCount = JSON.parse(this.element.dataset.optionsText);
      document.getElementById('variantCount').innerHTML = `${I18n[window.currentLocale]['variant']} (${variantsCount})`;
    }
  }

  getOptionsValues() {
    const currentVisit = window.location.pathname;
    const productId = currentVisit.includes('edit') ? currentVisit.split('/')[currentVisit.split('/').length - 2] : null;
    let dynamicId = currentVisit.includes('new') ? 'new_product' : `edit_product_${productId}`;
    let mainForm = document.getElementById(dynamicId);
    let optionValueController = this.application.getControllerForElementAndIdentifier(mainForm, 'pro-options');

    this.optionValues = optionValueController.dataParam;
  }
}
