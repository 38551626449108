import {Controller} from 'stimulus';

export default class extends Controller {

  routeToStoreFront(routerElement) {
    const url = routerElement.currentTarget.dataset.url;
    if (url) {
      window.location.href = url;
    }
  }
}