import {Controller} from 'stimulus';
import {popSnackbar} from '../services/snackbar';
import {I18n} from '../translation';
import {setMessage} from '../services/authentications/base_service';
import {VariantService} from '../services/variant_service';
import {validateLength} from '../services/authentications/min-length_service';
import {NumberToggleService} from '../services/number-toggle_service';

export default class extends Controller {
  selectedOptionIds = [];
  optionValues = {};
  variantImages = [];
  variantService = new VariantService();
  numberToggleService = new NumberToggleService();

  variantForm = document.getElementById('new_variant');

  optionSelected(event) {
    let optionName = event.currentTarget.dataset.optionName;
    let optionValue = event.currentTarget.dataset.value;
    let optionId = event.currentTarget.dataset.optionId; // id is what's actually sent
    let targetFieldId = event.currentTarget.dataset.targetField;
    let targetField = document.getElementById(targetFieldId);

    targetField.value = optionValue;

    this.optionValues[optionName] = optionId;

    this.addOptionId();
  }

  toggleNumberField(event) {
    this.numberToggleService.toggleNumberField(event);
  }

  onFieldChange(event) {
    const inputId = event.target.dataset.targetField || event.target.id
      || event.target.parentElement.dataset.targetField;
    const errorElement = document.getElementById(`${inputId}_error_message`);
    if (errorElement) setMessage(errorElement.id);
  }

  validateLength(e) {
    const lengthValid = validateLength(e.currentTarget);
    if (!lengthValid) e.stopImmediatePropagation();
  }

  updateStatus(e) {
    let statusField = document.getElementById('variant-status');

    statusField.value = e.currentTarget.checked ?  'active' : 'inactive';
  }

  validateFormAndCreateVariant() {
    const form = document.getElementById('new_variant');
    const valid = this.variantService.validateVariantForm(form);
    if (valid) this.createVariant();
  }

  createVariant() {
    let loader = document.getElementById('create-variant-form-submit-btn'); // loader
    loader.classList.remove('hidden');
    loader.parentElement.disabled = true;

    this.getVariantImages();
    const startDate = document.getElementById('select_date_range_add').value.split(' to ')[0];
    const endDate = document.getElementById('select_date_range_add').value.split(' to ')[1];

    document.getElementById('option-value-ids').value = JSON.stringify(this.selectedOptionIds);
    // document.getElementById('variant-images').value = JSON.stringify(this.variantImages);

    let formData = new FormData(this.variantForm);
    formData.append('variant[stock_items][threshold]',
      document.getElementById('add_variant_threshold_value').value);

    formData.append('variant[image_urls]', JSON.stringify(this.variantImages));
    formData.append('variant[pre_order_configs_attributes[][active]]', document.getElementById('pre-order-toggle-add').checked);
    formData.append('variant[pre_order_configs_attributes[][expected_stock]]', document.getElementById('stock-add').value || 0);
    formData.append('variant[pre_order_configs_attributes[][expected_start_date]]', startDate);
    formData.append('variant[pre_order_configs_attributes[][expected_end_date]]', endDate);
    formData.append('product[cac_enable]', document.getElementById('cac-enable-toggle-add').checked);
    formData.append('variant[cac_stock]', document.getElementById('click-collect-input').value);
    formData.append('variant[cac_intermediate_stock]', document.getElementById('click-collect-input').value);

    fetch('/admin/variants', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: formData
    }).then((response) => response.text())
      .then((html) => {
        let tmpDom = document.createElement('div');
        tmpDom.innerHTML = html;

        document.getElementById('modalAddVariant').click();

        document.getElementById('tab_content').replaceWith(tmpDom.firstChild);
        this.variantForm.reset();
        popSnackbar('notice', I18n[window.currentLocale].variant_creation_success_notice);
      });
  }

  resetFormValidations(event) {
    const form = document.getElementById('new_variant');
    const imageDom = document.getElementById(event.currentTarget.parentElement.dataset.imageDomId);
    const imageController = this.application.getControllerForElementAndIdentifier(document.getElementById('variant-image-uploader'), 'multiple-image-upload');
    this.variantService.resetFormValidations(form);
    form.dataset.variantImages = '';
    imageController.emptySelectedExistingImages(this.element);
    imageDom.innerHTML = '';
  }

  setInitialStock(e) {
    document.getElementById('variant-stock').value = e.currentTarget.value;
  }

  setThresholdValue(e) {
    document.getElementById('variant-stock-threshold').value = e.currentTarget.value;
  }

  ctrlStockMonitor(e) { // enable or disable monitor
    let thresholdFieldDom = document.getElementById('monitor-variant-stock');

    e.currentTarget.checked ? thresholdFieldDom.classList.remove('hidden') : thresholdFieldDom.classList.add('hidden');
  }

  addOptionId() {
    this.selectedOptionIds = [];

    Object.keys(this.optionValues).forEach((key) => {
      this.selectedOptionIds.push(this.optionValues[key]);
    });
  }

  getVariantImages() {
    const modalImageDom = document.getElementById('new_variant');
    this.variantImages = JSON.parse(modalImageDom.dataset.variantImages || '[]');
  }

  preOrderToggle(event){
    document.getElementById('variant-preorder-add').style.display = event.target.checked? 'block' : 'none';
  }
  clickCollectToggle(event){
    document.getElementById('click-collect-add').style.display = event.target.checked? 'block' : 'none';
  }
}
